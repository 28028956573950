import {Component, OnInit} from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {AuthService} from '../../auth/auth.service';
import {LanguageService} from '../../language.service';
import {I18NextService} from 'angular-i18next';
import {normalizeLanguageCode} from '@myshared/i18nutils';
import {NavigationService} from "../../navigation.service";

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: []
})
export class TopNavigationComponent extends AutorunComponent implements OnInit {

  public name: string;
  public isAuthorized: boolean;
  public isPartner: boolean;
  public isMypascomAdmin: boolean;
  public inWorkflow: boolean;
  public inMaintenance: boolean;
  public canEnterPhonenumbers = false;
  public isUserInCompany: boolean;
  public isMobileMenuActive: boolean = false;
  public hasAgreements: boolean;
  public showMenu = true;
  public showStickyMessages = false;
  public isPartnershipAllowed: boolean;

  constructor(
    private m: Model,
    private authService: AuthService,
    private i18next: I18NextService,
    private languageService: LanguageService,
    private navigationService: NavigationService
  ) { super(); }


  ngOnInit() {
    this.autorun(() => {
      this.name = this.m.account.currentUser.first_name + ' ' + this.m.account.currentUser.last_name;
      this.isAuthorized = this.m.account.isAuthorized;
      this.isPartner = this.m.account.currentUser.companyIsPartner;
      this.isMypascomAdmin = this.m.account.currentUser.mypascom_account === 'admin';
      this.inWorkflow = this.m.workflow.inWorkflow;
      this.inMaintenance = !this.m.maintenance.isReachable;
      this.canEnterPhonenumbers = this.m.account.hasTrunkInformationAccess;
      this.isUserInCompany = this.m.account.isInCompany;
      this.hasAgreements = this.m.agreement.agreements.length > 0;
      this.showStickyMessages = this.m.account.isSSOUser;
      this.isPartnershipAllowed = !this.m.affiliate.isPartnerDead;
      this.showMenu = this.navigationService.isMenu;
    });
  }

  public logout() {
    this.authService.logout();
  }

  public toggleLanguage() {
    const norm = normalizeLanguageCode(this.i18next.language);
    this.languageService.changeLanguage(norm === 'de_DE' ? 'en_US' : 'de_DE');
  }

  public toggleMobileMenu() {
    this.isMobileMenuActive = !this.isMobileMenuActive;
  }

  public openRoute() {
    this.isMobileMenuActive = false;
  }

  get partnerMediaLink() {
    return this.m.account.partnerMediaLink(this.i18next);
  }
}
