import {WorkflowHandlerComponent} from "./workflow-handler.component";
import {WorkflowService} from "./workflow.service";
import {WorkflowChangeCuComponent} from "./workflow-change-cu/workflow-change-cu.component";
import {workflowCspComponents, workflowCspServices} from "./csp/workflow-csp.module";
import {workflowPartnerComponents, workflowPartnerServices} from "./partner/workflow-partner.module";
import {workflowEndcustomerComponents, workflowEndcustomerServices} from "./endcustomer/workflow-endcustomer.module";
import {WorkflowChangeSlaComponent} from "./workflow-change-sla/workflow-change-sla.component";
import {
  EndcustomerUpgradeOrderCompleteComponent
} from "./components/endcustomer-upgrade-order-complete/endcustomer-upgrade-order-complete.component";
import {
  EndcustomerDowngradeOrderCompleteComponent
} from "./components/endcustomer-downgrade-order-complete/endcustomer-downgrade-order-complete.component";
import {
  PartnerUpgradeOfferSentComponent
} from "./components/partner-upgrade-offer-sent/partner-upgrade-offer-sent.component";
import {
  PartnerDowngradeOfferSentComponent
} from "./components/partner-downgrade-offer-sent/partner-downgrade-offer-sent.component";
import {WorkflowFallbackNumberComponent} from "./workflow-fallback-number/workflow-fallback-number.component";
import {
  EndcustomerNeedPascomApprovalComponent
} from "./components/endcustomer-need-pascom-approval/endcustomer-need-pascom-approval.component";

export const workflowComponents= [
  EndcustomerUpgradeOrderCompleteComponent,
  EndcustomerDowngradeOrderCompleteComponent,
  EndcustomerNeedPascomApprovalComponent,
  PartnerUpgradeOfferSentComponent,
  PartnerDowngradeOfferSentComponent,
  WorkflowHandlerComponent,
  WorkflowChangeCuComponent,
  WorkflowChangeSlaComponent,
  WorkflowFallbackNumberComponent,
  ...workflowPartnerComponents,
  ...workflowCspComponents,
  ...workflowEndcustomerComponents,
]

export const workflowServices = [
  WorkflowService,
  ...workflowPartnerServices,
  ...workflowCspServices,
  ...workflowEndcustomerServices,
]
