<app-content-header
  (backEvent)="back.emit()"
  [historyBack]="historyBack"
  [headline]="'select_lac_headline' | i18next">
</app-content-header>

<div class="row mt-5">
  <app-icon-card class="col-md-8 offset-md-2 mb-3" headline="select_lac">
    <div class="d-flex flex-column w-100" *ngIf="lacList && lacList.lac_list; else lacListLoading">
      <p-dropdown class="d-flex flex-column w-100"
                 optionLabel="caption"
                 optionValue="value"
                 [options]="lacList.lac_list"
                 [(ngModel)]="selectedLac"
                 [emptyMessage]="'app_empty_multiselect' | i18next"
                 [filter]="true"
                 [disabled]="isDisabled"
                 [readonly]="isDisabled"
                 filterBy="caption">
      </p-dropdown>
    </div>
    <ng-template #lacListLoading>
        <div class="spinner text-align-center">
            <img src="assets/images/loading.gif" alt="Loading content">
        </div>
    </ng-template>

      <div class="alert alert-warning mt-4" *ngIf="lacList && !lacList.is_filtered">
          <div class="d-flex">
              <i class="fa fa-exclamation-circle red mr-2 mt-1"></i>
              <div [innerHTML]="'lac_no_filtered' | i18next"></div>
          </div>
      </div>

    <div class="d-flex justify-content-end mt-3 form-container-footer">
      <button class="btn btn-primary" [disabled]="!selectedLac" (click)="next()">{{ 'select_lac_button' | i18next }}</button>
    </div>
  </app-icon-card>
</div>


