<div class="card card-nb h-100">
    <div class="card-body pb-0">
        <h2>{{ 'pascom_one_trial_product_name' | i18next }}</h2>

        <div class="alert alert-warning mb-3" *ngIf="isInApproval">
            <div class="d-flex">
                <i class="fa fa-exclamation-circle red mr-2 mt-1"></i>
                <div [innerHTML]="'endcustomer_need_pascom_approval_content' | i18next"></div>
            </div>
        </div>

        <div class="mt-2" [innerHTML]="'subscription_one_trial_details' | i18next: {daysLeft: subscription.trialDaysLeft}"></div>

        <div class="d-flex flex-column">
            <div class="d-flex mt-3">
                <div>
                    <i class="fa fa-info-circle red mr-2"></i>
                    <span class="subinformations" [innerHTML]="'subscription_one_trial_note' | i18next: {daysLeft: subscription.trialDaysLeft}"></span>
                </div>
            </div>
            <div class="d-flex mt-3 w-100 justify-content-end" *ngIf="isAdmin">
                <div class="d-flex align-items-center align-self-center" *ngIf="isInApproval; else elseApproval">
                    <i class="fa fa-exclamation-circle red mr-2"></i>
                    {{ 'account_in_approval' | i18next }}
                </div>
                <ng-template #elseApproval>
                    <button class="btn btn-outline-primary" (click)="doUpgradeSubscription()">
                        {{ 'subscription_upgrade_plan' | i18next }}
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
</div>
