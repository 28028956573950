import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-subscription-trial-details',
  templateUrl: './subscription-trial-details.component.html',
})
export class SubscriptionTrialDetailsComponent extends AutorunComponent implements OnInit {

  @Input() subscription: Subscription;

  public isAdmin: boolean;
  public isInApproval: boolean;


  constructor(private m: Model, private router: Router, private route: ActivatedRoute,) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
      this.isInApproval = this.m.account.currentUser.isInApproval;
    });
  }

  public doUpgradeSubscription() {
    this.router.navigate(['/do', 'upgrade', this.subscription.appliance_id, ''], {
      queryParams: {fromwf: this.route.snapshot.paramMap.get('b64')}
    });
  }
}
