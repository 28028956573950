import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrunkEnvironment} from "../../auth/account.model";

@Component({
  selector: 'app-select-phonenumber-blocksize',
  templateUrl: './select-phonenumber-blocksize.component.html',
})
export class SelectPhonenumberBlocksizeComponent implements OnInit {
  @Input() trunkEnvironment: TrunkEnvironment;
  @Output() selected: EventEmitter<number> = new EventEmitter<number>();
  blockSize: {name: number}[] = [];
  selectedBlockSize = 1;

  constructor() {
  }

  ngOnInit() {
    this.blockSize = this.trunkEnvironment.availableNumberBlockSizes.map(r => ({name: r}));
  }

  onBlockSizeSelected() {
    this.selected.emit(this.selectedBlockSize);
  }

  get selectablePhonenumbers() {
    return this.selectedBlockSize * 3;
  }

  get isDisabled() {
    return this.blockSize.length === 1;
  }

}
