import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {BuyService} from '../../buy/buy.service';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {AffiliateService} from '../../partner/affiliate/affiliate.service';
import {SubscriptionService} from '../../subscription/subscription.service';
import {BlockNumber, BlockOffer, TrunkNumberBlock} from '../../phonenumber/phonenumber.model';
import {Subscription} from '../../subscription/subscription.model';
import {LacList, PhonenumberService} from '../../phonenumber/phonenumber.service';
import {HttpClient} from '@angular/common/http';
import {PaymentMethodCode} from '../../buy/buy.model';
import {
  PortNumberBankAccount,
  PortNumberOffer,
  PortNumbersPortingFormDetails,
  PortNumberUploadForm
} from '../../phonenumber/port-phonenumber.model';
import {BankAccount, Company, Contact} from '../../company/company.model';
import {firstValueFrom} from "rxjs";
import {runInAction} from "mobx";
import {CompanyService} from "../../company/company.service";
import {Customer} from "../../customer/customer.model";

type BuyStep = 'select-subscription'
                | 'select-contact'
                | 'creating-offer'
                | 'select-type'
                | 'contact-sales'
                | 'port-prepare'
                | 'port-number'
                | 'upload-phonebill-form'
                | 'phonebill-company-name-check'
                | 'cancel-provider-connection'
                | 'download-port-form'
                | 'upload-port-form'
                | 'select-port-date'
                | 'upload-company-files'
                | 'select-blocksize'
                | 'invoice-address'
                | 'payment-method'
                | 'customer-invoice-address'
                | 'customer-payment-method'
                | 'select-phonenumber'
                | 'select-loc-ind-phonenumber'
                | 'numbers-unavailable'
                | 'checkout-summary'
                | 'thank-you-partner'
                | 'thank-you'
                | 'select-lac';

export type PhonenumberType = 'new' | 'port' | 'loc-independent';

function validateBuyStep(input: string, orderName: string): BuyStep|boolean {
  if (['select-contact', 'select-subscription', 'select-type', 'contact-sales',
    'customer-invoice-address', 'customer-payment-method', 'invoice-address', 'payment-method',
    'upload-company-files', 'port-prepare', 'port-number', 'cancel-provider-connection', 'download-port-form',
    'upload-port-form','select-port-date', 'upload-phonebill-form', 'phonebill-company-name-check', 'select-lac'].indexOf(input) !== -1) {
    return input as BuyStep;
  }
  if (orderName &&
    ['select-blocksize', 'select-phonenumber','select-loc-ind-phonenumber', 'checkout-summary', 'thank-you', 'thank-you-partner', 'numbers-unavailable']
      .indexOf(input) !== -1) {
    return input as BuyStep;
  }

  return false;
}

const ORDER_LINES_WHITELIST_STEPS: BuyStep[] = ['checkout-summary', 'thank-you', 'thank-you-partner'];

@Component({
  selector: 'app-workflow-phonenumbers',
  templateUrl: './workflow-phonenumbers.component.html',
  styleUrls: []
})
export class WorkflowPhonenumbersComponent extends AutorunComponent implements OnInit {

  public step: BuyStep;
  public orderName: string;
  public newNumberPrice: number;
  public surplusNumberPrice: number;
  private selectedApplianceId: string;
  public subscription: Subscription;
  public portingFormDetails: PortNumbersPortingFormDetails;
  public selectedCustomer: Customer;
  public company: Company;
  public selectedContactId: string;
  public portDate: Date;
  public paymentMethod: PaymentMethodCode;
  public isPC20CSP: boolean;
  public isPartner: boolean;
  public lacList: LacList;
  public selectedLac: string;

  private phonenumbersType: PhonenumberType;
  private portNumberOffer: PortNumberOffer;

  blocks: BlockOffer[];
  trunkNumbers: BlockNumber[];

  selectedBlockSize: number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private buyService: BuyService,
              private subscriptionService: SubscriptionService,
              private affiliateService: AffiliateService,
              private m: Model,
              private phonenumberService: PhonenumberService,
              private http: HttpClient,
              private companyService: CompanyService) {
    super();

    this.orderName = '';
    this.m.buy.resetCurrentOrder();

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('id')) {
        const id = params.get('id');
        if (id !== '') {
          this.orderName = id;
          this.buyService.refresh(this.orderName);
        }
      }
      if (params.has('subroute')) {
        const sr = params.get('subroute');

        const newStep = validateBuyStep(sr, this.orderName);
        if (newStep !== false) {
          this.setStep(newStep as BuyStep);
          this.checkWorkflowStep();
          return;
        }
      }
    });
  }

  ngOnInit() {
    if (!this.m.account.hasTrunkAccess && !this.m.account.currentUser.companyIsPartner) {
      this.navigateToStep('contact-sales');
      return;
    }

    if (!this.orderName) {
      // When no order name existing, we need to begin from choose appliance
      this.selectedApplianceId = '';
      this.navigateToStep('select-subscription');
    }

    if (this.step === 'select-phonenumber' && !this.blocks && !this.m.account.currentUser.trunkEnvironment.manualLacSelectionEnabled) {
      this.navigateToStep('select-blocksize', this.orderName);
    } else if (this.step === 'select-phonenumber' && this.m.account.currentUser.trunkEnvironment.manualLacSelectionEnabled) {
      this.checkLacNeededAndNavigate();
    }

    // On this initial steps, we will check where we need to go, and load data for lac if needed
    if (this.step === 'select-blocksize' || this.step === 'select-lac') {
      this.checkLacNeededAndNavigate();
    }

    this.autorun(async () => {
      this.isPartner = this.m.account.currentUser.companyIsPartner;
      this.isPC20CSP = this.m.account.currentUser.companyIsPC20Csp;
      // We need to reload subscription for some calculation
      if (!this.subscription && +this.m.buy.currentOrder.aid > 0) {
        // We need to wait for this call, otherwise we get an error on the next check
        const subscription = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+this.m.buy.currentOrder.aid));
        this.subscription = Subscription.fromJson(subscription);
      }

      // Partner offer
      if (!this.subscription?.is_my && !this.isPC20CSP) {
        // reload company of the customer
        if (!this.company && this.m.buy.currentOrder.name !== '') {
          let company = this.m.buy.currentOrder.invoice_address;
          if (company.bankAccounts.length <= 0) {
            try {
              company = await firstValueFrom(this.companyService.getCompany(company.id));
            } catch (e) {}
          }
          runInAction(() => this.company = company )
        }
      }
      if (this.m.buy.currentOrder?.name !== '') {
        this.paymentMethod = this.m.buy.currentOrder?.payment_method;
      }
    });

    this.phonenumberService.refresh(true);
  }

  private setStep(step: BuyStep) {
    this.step = step;
  }

  private navigateToStep(step: BuyStep, orderId?: string) {
    this.router.navigate(['/do', this.m.workflow.name, step, orderId ?? ''], {
      queryParamsHandling: 'preserve'
    });
  }

  onBack() {
    window.history.back();
  }

  private async loadSubscriptionData(applianceId: number): Promise<any> {
    return firstValueFrom(this.subscriptionService.getSubscriptionDetails(applianceId));
  }
  
  private getTrialPhoneNumberRoute(isMy: boolean, isPartner: boolean, isPC20CSP: boolean): string[] {
    if (isMy) {
      return ['/do', 'trial-phonenumber', 'add-phonenumber'];
    }
  
    const workflowUrl = isPartner
      ? (isPC20CSP ? ['csp', 'trial-phonenumber'] : ['partner', 'trial-phonenumber'])
      : ['trial-phonenumber'];
  
    return ['/do', ...workflowUrl, 'add-phonenumber'];
  }
  
  private extractTrunkNumbers(subscriptionData: any): BlockNumber[] {
    return TrunkNumberBlock.fromJson(subscriptionData).trunkNumbers;
  }
  
  public async onSubscriptionSelected(applianceId) {
    try {
      this.selectedApplianceId = applianceId;
  
      const subscriptionData = await this.loadSubscriptionData(+applianceId);
      this.subscription = Subscription.fromJson(subscriptionData);
  
      if (this.subscription.license_type === 'one_trial') {
        const route = this.getTrialPhoneNumberRoute(this.subscription.is_my, this.isPartner, this.isPC20CSP);
        await this.router.navigate(route, { queryParams: { aid: applianceId } });
        return;
      }
  
      this.paymentMethod = this.subscription.payment_method;
      this.trunkNumbers = this.extractTrunkNumbers(subscriptionData);
  
      if (this.subscription.is_my || this.isPC20CSP) {
        this.navigateToStep('select-type');
      } else {
        await this.loadCustomerData(this.subscription.customer_id);
      }
  
    } catch (error) {}
  }
  

private async loadCustomerData(customerId: string): Promise<void> {
  try {
    const company = await firstValueFrom(this.companyService.getCompany(customerId));
    runInAction(() => {
      this.company = company;
      this.selectedCustomer = new Customer(
        company.id,
        company.name,
        company.street,
        company.street2,
        company.zip,
        company.city,
        company.country,
        [],
        company.contacts
      );
      this.m.customer.customers[0] = this.selectedCustomer;
    });

    if (this.selectedCustomer) {
      this.navigateToStep('select-contact');
    }
  } catch (error) {}
}

  public onContactSelected(contact: Contact) {
    this.selectedContactId = contact.id;
    this.navigateToStep( 'select-type');
  }

  onPhoneTypeSelected(type: PhonenumberType) {
    this.phonenumbersType = type;
    // Restricts the next steps on phone number workflow, after selecting new phone number for AT customer.
    // The customer will get an information page to contact our sales team to order new number.
    if (this.phonenumbersType === 'new' && !this.subscription.trunkEnvironment.isNumberAllocationSupported) {
      this.navigateToStep('contact-sales');
      return;
    }

    if (this.isPortWorkflow) {
      // initial step for port phone number
      this.portNumberOffer = new PortNumberOffer();
      this.portNumberOffer.appliance_id = +this.selectedApplianceId;
      this.navigateToStep('port-prepare');
    } else {
      this.navigateToStep('upload-company-files');
    }
  }

  ////
  // PORTING PHONE NUMBER
  ////

  public onPortPrepare(): void {
    if (this.subscription.is_my || this.isPC20CSP) {
      this.navigateToStep('invoice-address');
    } else{
      this.navigateToStep('customer-invoice-address');
    }
  }

  public onPortNumbersSelected(formDetails: PortNumbersPortingFormDetails) {
    this.portNumberOffer.number_blocks = formDetails.numbers;
    this.portingFormDetails = formDetails;
    this.navigateToStep('upload-phonebill-form');
  }

  public onPhoneBillUploaded(form: PortNumberUploadForm) {
    this.portNumberOffer.phonebill_file = form;
    this.navigateToStep('phonebill-company-name-check');

  }

  public onCompanyName(name: string) {
    if (name) {
      this.portingFormDetails.company_name = name;
      this.portingFormDetails.company_id = +this.company.id;
      this.portNumberOffer.bill_company_name = name;
      if (this.subscription.trunkEnvironment.tenantCountryCode === 'DE') {
        this.navigateToStep('cancel-provider-connection');
      } else {
        this.portingFormDetails.cancel_connection = false;
        this.navigateToStep('download-port-form');
      }
    } else {
      this.navigateToStep('phonebill-company-name-check');
    }
  }

  public onPortFormOptionsSelected(cancelConnection: boolean) {
    this.portingFormDetails.cancel_connection = cancelConnection;
    this.navigateToStep('download-port-form');
  }

  public onPortFormDownloaded(event: any) {
    this.navigateToStep('upload-port-form');
  }

  public onPortFormUploaded(form: PortNumberUploadForm) {
    this.portNumberOffer.porting_form_file = form;
    if (!this.subscription.is_my && !this.isPC20CSP) {
      this.portNumberOffer.partner_id = +this.selectedContactId;
    }
    this.navigateToStep('select-port-date');
  }

  public onPortDateSelected(date: string) {
    this.portNumberOffer.delivery_date = date;
    this.buyService.createNumberBlockPortInOffer(this.portNumberOffer).subscribe(_ => {
      this.orderName = this.m.buy.currentOrder.name;
      this.navigateToStep('checkout-summary', this.orderName);
    });

  }

  ////
  // ORDER NEW NUMBER
  ////

  async onPhoneBlocksizeSelected(size: number) {
    this.selectedBlockSize = size;
    this.getPhonenumbersFromBlocksize();
  }

  public onPhonennumberSelected(blockIdsMap: Map<string, boolean>) {
    const blockIds: string[] = [];
    for (const [k, _] of blockIdsMap) {
      blockIds.push(k);
    }

    this.buyService.addPhonenumberToOffer(blockIds).subscribe(r => {
      this.navigateToStep('checkout-summary', this.orderName);
    });
  }

  public onLocIndPhonennumberSelected(blockId: string) {
    const blockIds: string[] = [];
    blockIds.push(blockId);

    this.buyService.addPhonenumberToOffer(blockIds).subscribe(r => {
      this.navigateToStep('checkout-summary', this.orderName);
    });
  }

  public onCustomerInvoiceAddressFilled(company: Company) {
    this.companyService.update(company).subscribe(ok => {
      const bankAccounts = this.company.bankAccounts;
      runInAction(() => {
        this.company = company;
        this.company.bankAccounts = bankAccounts;
      })

      if (this.isPortWorkflow) {
        this.navigateToStep('customer-payment-method');
      } else {
        this.navigateToStep('customer-payment-method', this.orderName);
      }
    });
  }

  public onInvoiceAddressFilled(company: Company) {
    this.company = company;
    if (this.isPortWorkflow) {
      this.navigateToStep('payment-method');
    } else {
      this.navigateToStep('payment-method', this.orderName);
    }
  }

  public async onCustomerPaymentMethodSelected(details: {bank?: BankAccount, method?: PaymentMethodCode}) {
    if (!this.isPortWorkflow) {
      details.bank.partner_id = +this.company.id;
    }

    if (details.method === 'sepa') {
      await firstValueFrom(this.companyService.updateBankAccount(details.bank));
    }

    if (this.isPortWorkflow) {
      this.navigateToStep('port-number');
    } else if (this.isLocationIndependentWorkflow) {
      this.onPhoneBlocksizeSelected(1);
    } else {
      this.checkLacNeededAndNavigate();
    }
  }

  public async onPaymentMethodSelected(details: {bank?: BankAccount, method?: PaymentMethodCode}) {
    if (details.method === 'sepa') {
      await firstValueFrom(this.companyService.updateBankAccount(details.bank));
    }

    if (this.isLocationIndependentWorkflow) {
      this.onPhoneBlocksizeSelected(1);
      return;
    }

    if (this.isPortWorkflow) {
      this.navigateToStep('port-number');
    } else {
      this.checkLacNeededAndNavigate();
    }
  }

  public onCompanyFilesUploaded(uploadData: {file_name: string, file_data: string}) {
    const data = {
      appliance_id: this.selectedApplianceId,
      partner_id: this.selectedContactId ?? undefined
    }
    this.buyService.createEmptyOffer(data).subscribe(_ => {
      this.orderName = this.m.buy.currentOrder.name;
      this.buyService.addCompanyCommercialRegisterToOffer(uploadData).subscribe(r => {
        if (this.subscription.is_my || this.isPC20CSP) {
          this.navigateToStep('invoice-address', this.orderName);
        } else {
          this.navigateToStep('customer-invoice-address', this.orderName);
        }
      });
    });
  }

  public onLacBack() {
    this.onBack();
  }

  public onLacSelected(lacValue: string) {
    this.selectedLac = lacValue;
    this.navigateToStep('select-blocksize', this.orderName);
  }

  private getPhonenumbersFromBlocksize() {
    this.buyService.getPhonenumbersByBlocksize(
      this.orderName,
      this.selectedBlockSize.toString(),
      this.isLocationIndependentWorkflow,
      this.selectedLac,
    ).subscribe({
        next: (r: []) => {
          this.blocks = r.map(b => BlockOffer.fromJson(b));
          this.navigateToStep(this.isLocationIndependentWorkflow ?
              'select-loc-ind-phonenumber' : 'select-phonenumber', this.orderName);
        },
        error: (err) => {
          this.navigateToStep('numbers-unavailable', this.orderName);
        }
    });
  }

  public onPhonenumberUnavailableBack() {
    this.checkLacNeededAndNavigate();
  }

  public onBuy() {
    if (this.m.account.currentUser.company_id !== this.m.buy.currentOrder.invoice_address.id) {
      this.onBuyPartner();
      return;
    }

    this.onBuyCustomer();
  }

  public resetWorkflow() {
    this.m.workflow.resetWorkflow();
  }

  public cancelWorkflow() {
    this.m.workflow.resetWorkflow();
    this.router.navigate(['/', 'phonenumbers']);
  }

  public get backLabel() {
    return this.isPortWorkflow ? 'app_cancel' : 'app_back';
  }

  public get isPortWorkflow(): boolean {
    return this.phonenumbersType === 'port';
  }

  public get isLocationIndependentWorkflow(): boolean {
    return this.phonenumbersType === 'loc-independent';
  }

  private onBuyPartner() {
    this.buyService.confirmPartnerQuotation().subscribe(_ => {
      this.navigateToStep('thank-you-partner', this.orderName);
    });
  }

  private onBuyCustomer() {
    this.buyService.userConfirmOffer().subscribe(_ => {
      this.navigateToStep('thank-you', this.orderName);
    });
  }

  /**
   * If someone clicks the browser's back button, we will cancel the workflow.
   * This means that, at this point, we already have sale order lines with numbers assigned to them and do not want to add more.
   * herefore, we can only handle the summary, thx pages and cannot navigate back using the browser.
   *
   * @private
   */
  private checkWorkflowStep() {
    if (this.m.buy.currentOrder?.lines?.length > 0 && !ORDER_LINES_WHITELIST_STEPS.includes(this.step)) {
      this.cancelWorkflow();
    }
  }

  private async checkLacNeededAndNavigate() {
  if (this.m.account.currentUser.trunkEnvironment.manualLacSelectionEnabled) {
      this.navigateToStep('select-lac', this.orderName);
    } else {
      this.navigateToStep('select-blocksize', this.orderName);
    }
  }
}
