import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import type {LacList} from "../../phonenumber/phonenumber.service";
import {PhonenumberService} from "../../phonenumber/phonenumber.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-select-lac',
  templateUrl: './select-lac.component.html'
})
export class SelectLacComponent implements OnInit {
  @Input() public historyBack?: boolean = true;
  @Input() public lacList: LacList;
  @Input() public saleOrderName: string;
  @Output() public readonly selected = new EventEmitter<string>();
  @Output() public readonly back = new EventEmitter();

  public selectedLac: any;

  constructor(
    private phonenumberService: PhonenumberService
  ) { }

  async ngOnInit() {
    try {
      this.lacList = await firstValueFrom(this.phonenumberService.getLacList(this.saleOrderName));
      this.lacList.lac_list.forEach(r => {
        r.caption = `${r.caption} (${r.value})`
      });
    } catch (e) {
      this.lacList = {
        is_filtered: false,
        lac_list: []
      }
    }
  }

  public next() {
    if (!this.selectedLac) return
    if (this.isDisabled) {
      this.selectedLac = this.lacList.lac_list[0].value;
    }
    this.selected.emit(this.selectedLac);
  }

  public get isDisabled() {
    return this.lacList.lac_list.length === 1
  }


}
