import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Model} from '../app.model';
import {Observable} from 'rxjs';
import {delay, map} from 'rxjs/operators';
import {PaymentMethodCode, SaleOrder} from './buy.model';
import {runInAction} from 'mobx';
import {PhonenumberBlock} from '../phonenumber/phonenumber.model';
import {PortNumberOffer} from '../phonenumber/port-phonenumber.model';

export interface InstanceOfferData {
  name: string;
  validationCode?: string;
  cspdomain?: string;
}

export interface UpgradeOfferData {
  cspdomain?: string;
}

export type CloudDomain = {
  domain: string;
  partner_id: string;
  subscription_id: string;
  state: string;
}

@Injectable()
export class BuyService {
  constructor(private m: Model,
              private http: HttpClient) {
  }

  public selectedProduct: string;

  public createUpgradeOffer(appliance_id: string): Observable<boolean> {
    const data = {
      appliance_id: appliance_id
    };

    return this.http.post<any>('/services/subscriptions/upgrade/create', data).pipe(map(r => {
      runInAction(() => this.m.buy.currentOrder = SaleOrder.fromJson(r));
      return true;
    }));
  }

  public createUpgradePascomOneOffer(data: {appliance_id: string; partner_id?: string}) {
    return this.http.post<any>('/services/subscriptions/upgrade/create', data).pipe(map(r => {
      runInAction(() => this.m.buy.currentOrder = SaleOrder.fromJson(r));
      return true;
    }));
  }
  public createUpgradePascomOneCrossTenantOffer(data: {appliance_id: string; partner_id: string, sla_product: string, cu: number }) {
    return this.http.post<any>('/services/subscriptions/cross_tenant/one/upgrade/create', data).pipe(map(r => {
      runInAction(() => this.m.buy.currentOrder = SaleOrder.fromJson(r));
      return true;
    }));
  }

  public changeProduct(product: string, slaProduct?: string, concurrentUsers?: number): Observable<boolean> {
    const data = {
      product: (product && product !== 'NONE' ? product : undefined),
      sla_product: (slaProduct && slaProduct !== 'NONE' ? slaProduct : undefined),
      users: (!!concurrentUsers ? concurrentUsers : undefined),
    };

    return this.http.post<any>('/services/subscriptions/upgrade/' + this.m.buy.currentOrder.name + '/product', data).pipe(map(r => {
      runInAction(() => this.m.buy.currentOrder = SaleOrder.fromJson(r));
      return true;
    }));
  }

  public confirmUpgrade(data?: UpgradeOfferData): Observable<{ name }> {
    return this.http.post<{ name }>('/services/subscriptions/upgrade/' + this.m.buy.currentOrder.name + '/confirm', data ?? {});
  }

  public confirmPartnerQuotation(): Observable<{ name }> {
    return this.http.post<{ name }>(`services/subscriptions/offer/shared/${this.m.buy.currentOrder.name}/send_quotation`, {});
  }

  public createEmptyOffer(data: {appliance_id: string; partner_id?: string}): Observable<boolean> {
    return this.http.post<SaleOrder>('/services/subscriptions/offer/create', data).pipe(map(r => {
      runInAction(() => {
        this.m.buy.currentOrder = SaleOrder.fromJson(r);
      });
      return true;
    }));
  }

  public createNumberBlockPortInOffer(numberOffer: PortNumberOffer): Observable<boolean> {
      return this.http.post<SaleOrder>('/services/subscriptions/offer/numberblockportin/create', numberOffer).pipe(map(r => {
      runInAction(() => {
        this.m.buy.currentOrder = SaleOrder.fromJson(r);
      });
      return true;
    }));
  }

  public confirmOffer(instanceOffer: InstanceOfferData, parentId: string = ''): Observable<{ name }> {
    const data = {
      instancename: instanceOffer.name,
      validation_code: instanceOffer.validationCode, // FIXME: REMOVE?????
      cspdomain: instanceOffer.cspdomain ?? undefined,
    } as any;
    if (parentId) {
      data.parent_id = parentId;
    }
    return this.http.post<{ name }>('/services/subscriptions/offer/' + this.m.buy.currentOrder.name + '/confirm', data);
  }

  public userConfirmOffer(): Observable<{ name }> {
    return this.http.post<{ name }>('/services/subscriptions/offer/' + this.m.buy.currentOrder.name + '/user_confirm', {});
  }

  public getPartnerOffer(token: string, offer: string) {
    return this.http.get<any>(`/services/subscriptions/offer/shared/${offer}`, {params: { token }})
      .pipe(map(r => {
        const saleOrder = SaleOrder.fromJson(r);
        runInAction(() => {
          this.m.buy.currentOrder = saleOrder
        })
        return saleOrder;
      }));
  }

  public confirmPartnerOffer(token: string, offer: string) {
    return this.http.post<any>(`/services/subscriptions/offer/shared/${offer}/confirm`, {},  {params: { token }})
  }

  public refresh(orderName: string) {
    this.http.get('/services/subscriptions/offer/' + orderName).subscribe({
      next: (r) => {
        runInAction(() => {
          this.m.buy.currentOrder = SaleOrder.fromJson(r)
        });
      }
    });
  }

  public getSaleOrder(orderName: string) {
    return this.http.get('/services/subscriptions/offer/' + orderName).pipe(
      map((r) => {
        const saleOrder = SaleOrder.fromJson(r);
        runInAction(() => {
          this.m.buy.currentOrder = saleOrder;
        });
        return saleOrder;
      }));
  }

  /**
   * Only be called as an CSP partner to get the recalculated price list sale order details
   * IMPORTANT: USE THIS FOR ALL CSP BUY WORKFLOWS
   *
   * @param orderName
   */
  public getOfferRecalculate(orderName: string) {
    return this.http.get(`/services/subscriptions/offer/${orderName}/recalculate`)
      .pipe(map(r => runInAction(() => this.m.buy.currentOrder = SaleOrder.fromJson(r))));
  }

  public addPhonenumberToOffer(blockIds: string[]) {
    const data = {
      'block_ids': blockIds
    };

    return this.http.post<any>('/services/subscriptions/offer/' + this.m.buy.currentOrder.name + '/add_number_blocks', data)
      .pipe(map(r => runInAction(() => this.m.buy.currentOrder = SaleOrder.fromJson(r))));
  }

  public addCompanyCommercialRegisterToOffer(fileData: {file_name: string, file_data: string}) {
    return this.http.post<any>('/services/subscriptions/offer/'
      + this.m.buy.currentOrder.name + '/upload_trading_license', fileData);
  }

  public getPhonenumbersByBlocksize(saleOrderId: string, size: string, isLocIndNumber: boolean, lac?: string) {
    let params = new HttpParams();
    params = params.append('blocksize', size);
    params = params.append('sale_order', saleOrderId);
    params = params.append('loc_independent', isLocIndNumber);
    if (lac) {
      params = params.append('lac', lac);
    }

    return this.http.get(`/services/trunk/phonenumber/enquiry`, {params: params});
  }

  public addProduct(productId: string, saleOrderId?: string) {
    const data = {
      product_id: productId
    };
    return this.http.post<any>('/services/subscriptions/offer/' + (saleOrderId ?? this.m.buy.currentOrder.name) + '/add_product', data);
  }

  public getDomains() {
    return this.http.get<CloudDomain[]>('/services/cspdomains');
  }
}
